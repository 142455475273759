import request from '@/service/index'

const registerAPI = {
  userRegister: '/portal/register',
  emailActivate: '/portal/activate'
}

export function userRegister(data) {
  return request.post({
    url: registerAPI.userRegister,
    data
  })
}

export function emailActivate(data) {
  return request.post({
    url: registerAPI.emailActivate,
    data
  })
}

export function emailResend(data) {
  return request.put({
    url: '/portal/activate',
    data
  })
}
