import KHrequest from './request'
import router from '@/router'
import localCache from '@/utils/cache'

import { BASE_URL, TIME_OUT } from './request/config'
import { ElMessage } from 'element-plus'

const request = new KHrequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      const token = JSON.parse(window.localStorage.getItem('token')) ?? null
      const user_id =
        JSON.parse(window.localStorage.getItem('userInfo'))?.user_id ?? null

      if (token && user_id) {
        // config.headers = { Authorization: `Bearer ${token}`, user_id: user_id }
        config.headers = { token: token, user_id: user_id }
      }

      return config
    },
    requestInterceptorCatch: (err) => {
      console.log('请求失败拦截')
      return err
    },
    responseInterceptor: (res) => {
      // console.log('请求成功回应的拦截')
      return res
    },
    responseInterceptorCatch: (err) => {
      // console.log('请求成功回应的拦截')

      if (err?.response?.status == 401 || err?.response?.status == 403) {
        ElMessage({
          message: '登录超时，请重新登录',
          type: 'warning',
          grouping: true
        })
        localCache.deleteCache('token')
        localCache.deleteCache('userInfo')

        router.push(`/login`)
      } else if (err?.response?.status == 500) {
        ElMessage({
          message: '服务器错误',
          type: 'warning',
          grouping: true
        })
      }

      return err
    }
  }
})

export default request
