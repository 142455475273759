export const zhSettingMain = {
  menu_list: {
    edit_profile: '修改个人资料',
    bind_email: '修改邮箱',
    edit_password: '修改密码'
  },
  header: {
    logout_btn: '退出登录',
    logout_success: '退出登录成功！'
  }
}

export const enSettingMain = {
  menu_list: {
    edit_profile: 'Profile',
    bind_email: 'Email',
    edit_password: 'Password'
  },
  header: {
    logout_btn: 'Logout',
    logout_success: 'Log out successfully!'
  }
}
