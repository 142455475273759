export const enInvite = {
  title: 'Invite registration',
  link_expired: 'Your invitation link has expired',
  submit_btn: 'Submit',
  invite_success_msg:
    'Successfully and you will to the login page in 3 seconds!'
}
export const zhInvite = {
  title: '邀请注册',
  link_expired: '您的邀请链接已过期',
  submit_btn: '提交',
  invite_success_msg: '注册成功，3秒后将跳转到登录页面!'
}
