import request from '@/service/index'

const loginApi = {
  getLoginCaptchaId: '/portal/captcha',
  getLoginCaptchaImage: '/portal/captcha/',
  accountLogin: '/portal/login',
  getCallback: '/portal/validate'
}

// 业务逻辑：获取验证码图片id，根据图片id去获取验证码图片
// 获取验证码图片id
export function getLoginCaptchaId() {
  return request.post({
    url: loginApi.getLoginCaptchaId
  })
}

// 获取图片
export function getLoginCaptchaImage(image_id, params) {
  return request.get({
    url: loginApi.getLoginCaptchaImage + image_id + '.png',
    params
  })
}

// 登录
export function accountLogin(data) {
  return request.post({
    url: loginApi.accountLogin,
    data
  })
}

export function getCallbackByToken(data) {
  return request.post({
    url: loginApi.getCallback,
    data
  })
}
