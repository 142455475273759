export const zhResetPassword = {
  title: '重置密码',
  hint: {
    hint_text_head: '请输入您的用户名或邮箱',
    hint_text_tail: '系统将通过给您的邮箱发送验证码来验证您的身份！'
  },
  next_btn: '下一步',
  pre_btn: '上一步',
  to_login_btn: '返回登录页',
  reset_success_title: '提示',
  reset_success_mgs: '重置密码成功！快去登录吧！',
  success_to_login: '前往登录',
  send_success: '邮箱验证码发送成功',
  form: {
    username_email: '用户名/邮箱',
    captcha_text: '验证码',
    refresh_code: '看不清? 换一张'
  },
  rules: {
    username_email: {
      required: '用户名或邮箱不能为空'
    },
    captcha_text: {
      required: '验证码不能为空'
    }
  },
  set_pwd_hint_head: '请为您的账号',
  set_pwd_hint_tail: '设置新密码'
}

export const enResetPassword = {
  title: 'Reset Password',
  hint: {
    hint_text_head: 'Please enter your username or email.',
    hint_text_tail:
      'uniAccount will verify your identity by sending a captcha code to your email!'
  },
  next_btn: 'Next',
  pre_btn: 'Previous',
  to_login_btn: 'Login',
  reset_success_title: 'Hint',
  reset_success_mgs: 'Reset successful! Go login now!',
  success_to_login: 'Login',
  send_success: 'Captcha code sent successfully',
  form: {
    username_email: 'Username/Email',
    captcha_text: 'Captcha Code',
    refresh_code: 'Refresh'
  },
  rules: {
    username_email: {
      required: 'Required'
    },
    captcha_text: {
      required: 'Required'
    }
  },
  set_pwd_hint_head: 'Please set a new password for your account ',
  set_pwd_hint_tail: '.'
}
