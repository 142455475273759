import { defineStore } from 'pinia'
import { LOGIN_CAPTCHA } from '@/service/request/config'
import router from '@/router/index'
import localCache from '@/utils/cache'
import { sp_to_callback } from '@/utils/sp'

import i18n from '@/locales/index.js'
const { t } = i18n.global

import {
  getLoginCaptchaId,
  accountLogin,
  getCallbackByToken
} from '@/service/login/login'
import { ElMessage } from 'element-plus'

import { emailActivate } from '@/service/register/register'

const useUserStore = defineStore('user', {
  state: () => ({
    registerAgreement: false, //注册协议
    registerModal: 1,
    registerEmailForm: {},

    // login
    email_verified: true,
    token: '',
    userInfo: {},
    image_id: '',
    image_url: ''
  }),
  getters: {},
  actions: {
    // 登录
    async accountLogin(data) {
      const res = await accountLogin({
        ...data,
        app: window.sessionStorage.getItem('app') ?? undefined,
        org: window.sessionStorage.getItem('org') ?? undefined,
        goto: window.sessionStorage.getItem('goto') ?? undefined
      })

      if (res.code == 200) {
        if (res.result.email_verified) {
          // 邮箱已验证 登录成功
          ElMessage({
            message: t('login.login_success'),
            type: 'success'
          })

          // 这里判断是否做跳转处理
          if (res.result.callback) {
            sp_to_callback(
              res.result.callback,
              res.result.ticket,
              res.result.goto
            )
          } else {
            router.push('/setting')
          }

          this.userInfo = {
            user_id: res.result.user_id,
            username: res.result.username,
            email: res.result.email
          }
          this.token = res.result.token
          localCache.setCache('token', this.token)
          localCache.setCache('userInfo', this.userInfo)

          // 清楚注册缓存
          window.sessionStorage.removeItem('registerAccountForm')
          window.sessionStorage.removeItem('registerEmailForm')
        } else {
          // 邮箱未激活 前往注册页激活邮箱
          this.email_verified = false
          window.sessionStorage.setItem(
            'email_verified',
            JSON.stringify(this.email_verified)
          )

          this.registerEmailForm = {
            user_id: res.result.user_id,
            username: res.result.username,
            email: res.result.email,
            csrf_token: res.result.csrf_token
          }
          window.sessionStorage.setItem(
            'registerEmailForm',
            JSON.stringify(this.registerEmailForm)
          )
          // 判断注册页面上一步去哪里
          window.sessionStorage.setItem(
            'isLoginToRegisterEmail',
            JSON.stringify(true)
          )
          // 邮箱未激活
          ElMessage({
            message: t('login.emailNoActivate'),
            type: 'warning'
          })

          router.push('/register')
        }
      } else {
        ElMessage({
          message: res.message,
          type: 'warning'
        })
        this.fetchCaptchaImage()
      }
    },

    // 获取验证码
    async fetchCaptchaImage() {
      const res = await getLoginCaptchaId()
      this.image_id = res.result.image_id
      this.image_url = `${LOGIN_CAPTCHA}/portal/captcha/${this.image_id}.png?width=150&height=75`
    },

    // 退出登录
    initUserExit() {
      this.$reset()
      localCache.deleteCache('token')
      localCache.deleteCache('userInfo')
      router.push('/login')
    },

    // 同意协议
    changeRegisterAgreement() {
      this.registerAgreement = true
    },

    // 注册
    accountRegister(data) {
      this.registerEmailForm = data
      sessionStorage.setItem('registerEmailForm', JSON.stringify(data))
      // localStorage.setItem('registerEmailForm', JSON.stringify(data))
    },

    // 激活邮箱
    async accountEmailActive(activate_code) {
      const res = await emailActivate({
        activate_code: activate_code,
        ...this.registerEmailForm,
        app: window.sessionStorage.getItem('app') ?? undefined,
        org: window.sessionStorage.getItem('org') ?? undefined,
        goto: window.sessionStorage.getItem('goto') ?? undefined
      })

      // 激活成功
      if (res.code == 200) {
        if (!this.email_verified) {
          // 激活成功
          ElMessage({
            message: t('register.activited_success'),
            type: 'success'
          })
          this.email_verified = true
          window.sessionStorage.setItem(
            'email_verified',
            JSON.stringify(this.email_verified)
          )
        } else {
          // 注册成功
          ElMessage({
            message: t('register.register_success'),
            type: 'success'
          })
        }

        this.token = res.result.token
        this.userInfo = {
          user_id: res.result.user_id,
          username: res.result.username,
          email: res.result.email
        }
        localCache.setCache('token', this.token)
        localCache.setCache('userInfo', this.userInfo)

        // 清楚注册的缓存
        window.sessionStorage.removeItem('registerAccountForm')
        window.sessionStorage.removeItem('registerModal')
        window.sessionStorage.removeItem('registerTime')
        window.sessionStorage.removeItem('registerEmailForm')

        // 需要跳转
        if (res.result.callback && res.result.ticket && res.result.goto) {
          sp_to_callback(
            res.result.callback,
            res.result.ticket,
            res.result.goto
          )
          // console.log('返回来有跳转信息，需要跳转')
        } else {
          router.push('/setting')
        }
      } else {
        ElMessage({
          message: res.message,
          type: 'warning'
        })
      }
    },

    // 已登录，根据token 获取callback
    async getCallbackByToken(params) {
      const res = await getCallbackByToken({
        ...params,
        user_id: JSON.parse(window.localStorage.getItem('userInfo')).user_id
      })

      if (res.code == 200) {
        if (res.result.callback)
          sp_to_callback(
            res.result.callback,
            res.result.ticket,
            res.result.goto
          )
      }
    },

    // 更新userInfo
    updateUserInfo(userInfo) {
      this.userInfo = userInfo
      localCache.setCache('userInfo', this.userInfo)
    },

    // 持久化存储 从缓存里取信息
    loadLocalLogin() {
      const token = localCache.getCache('token')
      const userInfo = localCache.getCache('userInfo')

      const registerEmailForm = JSON.parse(
        window.sessionStorage.getItem('registerEmailForm')
      )

      const email_verified =
        JSON.parse(window.sessionStorage.getItem('email_verified')) ?? true

      this.token = token || ''
      this.userInfo = userInfo || {}
      this.registerEmailForm = registerEmailForm || {}
      this.email_verified = email_verified
    }
  }
})

export default useUserStore
