import { zhWindowTitle } from './module/env/window-title'

import { zhLogin } from './module/login'
import { zhRegister } from './module/register'

// settiing
import { zhProfile } from './module/setting/profile'
import { zhSettingMain } from './module/setting/setting'
import { zhEmail } from './module/setting/email'
import { zhPassword } from './module/setting/password'
import { zhResetPassword } from './module/reset-password'
import { zhInvite } from './module/invite'

export default {
  env: {
    windowTitle: zhWindowTitle
  },

  login: { ...zhLogin },
  register: { ...zhRegister },
  resetPassword: { ...zhResetPassword },
  invite: { ...zhInvite },
  // setting
  settingMain: { ...zhSettingMain },
  profile: { ...zhProfile },
  email: { ...zhEmail },
  password: { ...zhPassword }
}
