import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
import { ElConfigProvider } from 'element-plus';
import { storeToRefs } from 'pinia';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import en from 'element-plus/es/locale/lang/en';
import useLocaleStore from '@/store/modules/locales.js';
import useUserStore from '@/store/modules/user'; // 国际化

export default {
  __name: 'App',

  setup(__props) {
    var _searchParams$get;

    const localeStore = useLocaleStore();
    const {
      locale
    } = storeToRefs(localeStore); // 1. 清楚sessionStorage

    window.sessionStorage.removeItem('app');
    window.sessionStorage.removeItem('goto');
    window.sessionStorage.removeItem('org'); // 2. 从url 中获取参数
    // http://localhost:8085/register?a=111&b=123

    let geturl = window.location.href;
    const params = new URL(geturl);
    const searchParams = params.searchParams;
    const app = searchParams.get('app');
    const goto = searchParams.get('goto');
    const org = (_searchParams$get = searchParams.get('org')) !== null && _searchParams$get !== void 0 ? _searchParams$get : ''; // 3. 三者都有 存sessionStorage

    if (app && org) {
      window.sessionStorage.setItem('app', app);
      window.sessionStorage.setItem('goto', goto);
      window.sessionStorage.setItem('org', org);
      console.log(org, app, goto);
    } // 4. 判断是否已登录 token


    const token = JSON.parse(window.localStorage.getItem('token'));
    const userStore = useUserStore(); // 5. 如果有token 通过token去发送请求

    if (token && app && org) {
      userStore.getCallbackByToken({
        org: org,
        app: app,
        goto: goto,
        token: token
      });
    }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(ElConfigProvider), {
        locale: _unref(locale) == 'zh' ? _unref(zhCn) : _unref(en)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"])]);
    };
  }

};