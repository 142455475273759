export const zhPassword = {
  curr_password: '旧密码',
  new_password: '新密码',
  confirm_password: '确认新密码',
  hint_text: {
    curr_password_hint: '请输入您的旧密码',
    new_password_hint: '请输入您的新密码',
    confirm_password_hint: '请再次输入密码'
  },
  save_btn: '确定',
  save_success: '修改密码成功，请重新登录',
  form_rules: {
    curr_password: {
      required: '旧密码不能为空'
    },
    new_password: {
      required: '新密码不能为空',
      length: '密码长度不能小于5'
    },
    confirm_password: {
      required: '请再次输入密码',
      no_equal: '两次输入的密码不一致'
    }
  }
}

export const enPassword = {
  curr_password: 'Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  hint_text: {
    curr_password_hint: '',
    new_password_hint: '',
    confirm_password_hint: ''
  },
  save_btn: 'Save',
  save_success: 'Successfully, please login again',
  form_rules: {
    curr_password: {
      required: 'Required'
    },
    new_password: {
      required: 'Required',
      length: 'The length cannot be < 5'
    },
    confirm_password: {
      required: 'Required',
      no_equal: 'Passwords are inconsistent'
    }
  }
}
