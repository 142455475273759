export const zhWindowTitle = {
  login: '登录',
  register: '注册',
  reset_pwd: '找回密码',
  invite: '邀请',
  profile: '修改个人资料',
  email: '修改邮箱',
  password: '修改密码'
}
export const enWindowTitle = {
  login: 'Login',
  register: 'Register',
  reset_pwd: 'Reset Password',
  invite: 'Invite',
  profile: 'Profile',
  email: 'Email',
  password: 'Password'
}
