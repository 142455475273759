export const zhLogin = {
  title: '登录',
  account: '用户名/邮箱',
  password: '密码',
  verifyCode: '验证码',
  refreshVerifyCode: '看不清? 换一张',
  loginBtn: '登录',
  registerBtn: '立即注册',
  forgetPwdBtn: '忘记密码',

  // 验证
  verifyFormat: {
    username: '用户名或邮箱不能为空',
    password: '密码不能为空',
    captcha_text: '验证码不能为空'
  },

  login_success: '登录成功！',
  emailNoActivate: '邮箱未激活，请激活邮箱'
}

export const enLogin = {
  title: 'Sign In',
  account: 'Email address or Username',
  password: 'Password',
  verifyCode: 'Captcha Code',
  refreshVerifyCode: 'Refresh',
  loginBtn: 'Login',
  registerBtn: 'Register',
  forgetPwdBtn: 'Forget Password',

  // 验证
  verifyFormat: {
    username: 'Required',
    password: 'Required',
    captcha_text: 'Required'
  },

  login_success: 'Login successful',
  emailNoActivate: 'Email is not activated, please activate your email'
}
