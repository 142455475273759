// 根据process.env.NODE_ENV
// 开发环境：development
// 生产环境：production
// 测试环境：test

let BASE_URL = ''
const TIME_OUT = 10000
let LOGIN_CAPTCHA = ''

if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/api'
  // LOGIN_CAPTCHA = 'http://172.16.51.166:9991'
  LOGIN_CAPTCHA = '/api'
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = '/api'
  LOGIN_CAPTCHA = '/api'
} else {
  BASE_URL = ''
}

export { BASE_URL, TIME_OUT, LOGIN_CAPTCHA }
