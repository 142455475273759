export const zhProfile = {
  form: {
    avatar: '头像',
    username: '用户名',
    account_type: {
      account_title: '账号类型',
      account_1_ordinary: '普通账号',
      account_2_professional: '专业账号'
    },
    email: '邮箱',
    bind_email_btn: '修改邮箱',
    phone: '联系电话',
    phone_hint: '请输入您的联系电话',
    first_name: '名',
    first_name_hint: '请输入您的名',
    last_name: '姓',
    last_name_hint: '请输入您的姓',
    birthday: '出生日期',
    gender: '性别',
    male: '男',
    female: '女',
    country: '国家',
    country_hint: '请输入您所在的国家',
    city: '城市',
    city_hint: '请输入您所在的城市'
  },
  submit_btn: '保存',
  success_msg: '资料修改成功'
}

export const enProfile = {
  form: {
    avatar: 'Avatar',
    username: 'Username',
    account_type: {
      account_title: 'Account Type',
      account_1_ordinary: 'Ordinary',
      account_2_professional: 'Professional'
    },
    email: 'Email',
    bind_email_btn: 'Modify',
    phone: 'Mobile',
    phone_hint: '',
    first_name: 'First Name',
    first_name_hint: '',
    last_name: 'Last Name',
    last_name_hint: '',
    birthday: 'Birthday',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    country: 'Country',
    country_hint: '',
    city: 'City',
    city_hint: ''
  },
  submit_btn: 'Save',
  success_msg: 'Modified successfully'
}
