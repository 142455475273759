let isFirst = true
// pc
function pc() {
  var _fontsize = 625
  if (document.documentElement.clientWidth < 1400) {
    _fontsize = (1400 / 1920) * 625
  } else if (
    document.documentElement.clientWidth /
      document.documentElement.clientHeight >
    3
  ) {
    _fontsize = ((document.documentElement.clientHeight * 3) / 1920) * 625
  } else {
    _fontsize = (document.documentElement.clientWidth / 1920) * 625
  }
  document.documentElement.style.fontSize = _fontsize + '%'
}

// 移动
function mobile() {
  document.documentElement.style.fontSize =
    (document.documentElement.clientWidth / 750) * 625 + '%'
}

// 判断是否移动端
function isMobile() {
  function params(u, p) {
    var m = new RegExp('(?:&|/?)' + p + '=([^&$]+)').exec(u)
    return m ? m[1] : ''
  }
  return (
    /iphone|android|ipod/i.test(navigator.userAgent.toLowerCase()) == true &&
    params(location.search, 'from') != 'mobile'
  )
}

// 窗口变化监听
function resize() {
  window.addEventListener('resize', function () {
    if (!isFirst) {
      initScreen()
    }
  })
  if (isFirst) {
    initScreen()
    isFirst = false
  }
}

// 根字体设置
function initScreen() {
  isMobile() ? mobile() : pc()
}

resize()
