export const zhEmail = {
  curr_email: '当前邮箱',
  password: '密码',
  new_email: '新邮箱',
  email_verify_code: '邮箱验证码',
  send_code: '发送验证码',
  send_code_hint: '发送验证码成功，请留意新邮箱中的邮件',
  save_btn: '保存',
  save_success: '修改邮箱成功',
  hint_text: {
    password_hint: '请输入您的密码',
    new_email_hint: '请输入您的新邮箱',
    code_hint: '请输入验证码',
    text_hint_head: '系统将向新邮箱发送一封验证码邮件，请输入邮件中的验证码。',
    text_hint_tail:
      '如没收到邮件，请确认邮箱地址是否正确，或者在垃圾箱里检查是否误投。'
  }
}

export const enEmail = {
  curr_email: 'Email',
  password: 'Password',
  new_email: 'New Email',
  email_verify_code: 'Captcha Code',
  send_code: 'Send Code',
  send_code_hint:
    'We have sent a verification code to your email, please check it.',
  save_btn: 'Save',
  save_success: 'Modified successfully',
  hint_text: {
    password_hint: '',
    new_email_hint: '',
    code_hint: '',
    text_hint_head:
      'uniAccount will send a verification code to your email, Please enter the verification code you receive.',
    text_hint_tail:
      'Please make sure your email address is correct. Check your junk mailbox if you du not receive verification code.'
  }
}
