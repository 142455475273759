export const zhRegister = {
  // 用户协议
  agreement_title: 'uniAccount 用户协议',
  agreen_btn: '同意',
  not_agreen_btn: '不同意',

  // 注册
  register_title: '注册',
  username: '用户名',
  email: '邮箱',
  password: '密码',
  confirm_password: '确认密码',
  captcha_text: '验证码',
  refresh_captcha: '看不清? 换一张',
  next_btn: '下一步',
  has_account: '已有账号? 去登录',

  form_rules: {
    username: {
      required: '用户名不能为空',
      pattern: '需由5~15个字符，由字母、数字、点、下划线组成'
    },
    email: {
      required: '邮箱不能为空',
      pattern: '邮箱格式错误'
    },
    captcha_text: {
      required: '验证码不能为空'
    },
    password: {
      required: '密码不能为空',
      length: '密码长度不能小于5'
    },
    confirm_password: {
      required: '请再次输入密码',
      no_equal: '两次输入的密码不一致'
    }
  },

  // 邮箱激活
  email_verify_title: '账户激活',
  hint_text_no_activited: '您的账户还未激活',
  hint_text_action_head: 'uniAccount 将向您的邮箱',
  hint_text_action_tail: '发送一封验证码邮件',
  captcha_text_placeholder: '请输入验证码',
  pre_btn: '上一步',
  please_enter_captcha: '请输入邮箱验证码',
  not_received_email: '未收到验证码，重新发送！',
  send_email_success: '邮箱验证码发送成功!',
  later_send_head: '',
  later_send: 's后可重新发送',

  register_success: '注册成功！',
  activited_success: '激活成功！'
}

export const enRegister = {
  // 用户协议
  agreement_title: 'uniAccount User Agreement',
  agreen_btn: 'Agree',
  not_agreen_btn: 'Disagree',

  // 注册
  register_title: 'Register',
  username: 'Username',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confrim Password',
  next_btn: 'Next',
  captcha_text: 'Captcha Code',
  refresh_captcha: 'Refresh',
  has_account: 'Already have an account? Log in',

  form_rules: {
    username: {
      required: 'Required',
      pattern: '5 to 15 characters, consisting of (letters numbers . _).'
    },
    email: {
      required: 'Required',
      pattern: 'Email format error'
    },
    captcha_text: {
      required: 'Required'
    },
    password: {
      required: 'Required',
      length: 'The length cannot be < 5'
    },
    confirm_password: {
      required: 'Required',
      no_equal: 'Passwords are inconsistent'
    }
  },

  // 邮箱激活
  email_verify_title: 'Account Activation',
  hint_text_no_activited: 'Your account has not been activated yet.',
  hint_text_action_head:
    'uniAccount will send a verification code to your email ',
  hint_text_action_tail: '.',
  captcha_text_placeholder: 'Captcha code',
  pre_btn: 'Previous',
  please_enter_captcha: 'Please enter the email captcha code',
  not_received_email: 'Not received, resend',
  send_email_success: 'Captcha code sent successfully!',
  later_send_head: 'Reset(',
  later_send: 's)',

  register_success: 'Register successfully',
  activited_success: 'Verify successfully'
}
