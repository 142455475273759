import { createRouter, createWebHistory } from 'vue-router'

import i18n from '@/locales/index.js'
const { t } = i18n.global

const routes = [
  {
    path: '',
    redirect: `/login`,
    name: `Redirect`
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: t('env.windowTitle.login')
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/register.vue'),
    meta: {
      title: t('env.windowTitle.register')
    }
  },
  {
    path: '/invite/:invitecode',
    name: 'invite',
    component: () => import('@/views/invite/invite.vue'),
    meta: {
      title: t('env.windowTitle.invite')
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/reset-password/reset-password.vue'),
    meta: {
      title: t('env.windowTitle.reset_pwd')
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/setting.vue'),
    children: [
      {
        path: '',
        name: 'settingRedirect',
        redirect: `/setting/profile`
      },
      {
        path: '/setting/email',
        name: 'email',
        component: () => import('@/views/setting/bind-email/bind-email.vue'),
        meta: {
          title: t('env.windowTitle.email')
        }
      },
      {
        path: '/setting/phone',
        name: 'phone',
        component: () => import('@/views/setting/bind-phone/bind-phone.vue')
      },
      {
        path: '/setting/password',
        name: 'password',
        component: () =>
          import('@/views/setting/edit-password/edit-password.vue'),
        meta: {
          title: t('env.windowTitle.password')
        }
      },
      {
        path: '/setting/third-bind',
        name: 'third-bind',
        component: () => import('@/views/setting/third-bind/third-bind.vue')
      },
      {
        path: '/setting/profile',
        name: 'profile',
        component: () =>
          import('@/views/setting/edit-profile/edit-profile.vue'),
        meta: {
          title: t('env.windowTitle.profile')
        }
      }
    ]
  },
  // {
  //   path: '/main',
  //   name: 'main',
  //   component: () => import('@/views/main/main.vue')
  // },
  {
    path: '/:pathMath(.*)*',
    name: 'not-found',
    component: () => import('../views/not-found/not-found.vue'),
    meta: {
      title: '找不到该页面｜uniAccount'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta.title
    ? to.meta.title + ' | uniAccount'
    : 'uniAccount'

  const isLogin = window.localStorage.getItem('token')
  if (
    to.path !== '/login' &&
    to.path !== '/register' &&
    to.path !== '/reset-password' &&
    to.name !== 'invite' &&
    !isLogin
  ) {
    return {
      name: 'login'
    }
  }

  if (to.path === '/login' && isLogin) {
    return {
      name: 'profile'
    }
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
