import { defineStore } from 'pinia'
import i18n from '@/locales'

const useLocaleStore = defineStore('locale', {
  state: () => ({
    // zh en
    locale: i18n.global.locale.value //zh en
  }),
  getters: {
    isEnglishLocale: (state) => {
      return state.locale == 'en'
    }
  },
  actions: {
    setLocale(lang) {
      this.locale = lang
      location.reload()

      i18n.global.locale.value = lang
      localStorage.setItem('locales', JSON.stringify({ locale: lang }))
    },
    toggleLocale() {
      let lang = this.locale == 'zh' ? 'en' : 'zh'
      this.setLocale(lang)
    },
    loginUserLang(userLang) {
      let lang = userLang == 'english' ? 'en' : 'zh'
      this.setLocale(lang)
    }
  }
})

export default useLocaleStore
