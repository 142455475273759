/**
 *
 * @param {string} callback callback地址
 * @param {string} ticket ticket密钥
 */
export const sp_to_callback = (callback, ticket, goto) => {
  // if (callback.indexOf('?'))
  // let path = `${callback}&ticket=${ticket}&goto=${goto}`
  // if (callback.charAt(callback.length - 1) == '?') {
  //   path = `${callback}?ticket=${ticket}&goto=${goto}`
  // } else if (callback.charAt(callback.length - 1) == '') {
  //   console.log(path)
  // }
  // 不可返回
  // window.location.replace(path)

  // 可返回
  // window.location.assign('https://www.baidu.com?a=123&b=345')

  let path = ''
  if (callback.indexOf('?') != -1) {
    path = `${callback}&ticket=${ticket}&goto=${goto}`
  } else {
    path = `${callback}?ticket=${ticket}&goto=${goto}`
  }

  window.location.replace(path)
  // console.log(path)
}
