import { enWindowTitle } from './module/env/window-title'

import { enLogin } from './module/login'
import { enRegister } from './module/register'

// setting
import { enProfile } from './module/setting/profile'
import { enSettingMain } from './module/setting/setting'
import { enEmail } from './module/setting/email'
import { enPassword } from './module/setting/password'
import { enResetPassword } from './module/reset-password'
import { enInvite } from './module/invite'
export default {
  env: {
    windowTitle: enWindowTitle
  },

  login: { ...enLogin },
  register: { ...enRegister },
  resetPassword: { ...enResetPassword },
  invite: { ...enInvite },

  // setting
  settingMain: { ...enSettingMain },
  profile: { ...enProfile },
  email: { ...enEmail },
  password: { ...enPassword }
}
